
import { defineComponent } from "vue";

export default defineComponent({
  name: "Player",
  props: ["player"],
  data: function() {
    return {
      name: this.$props.player.name,
      isEditing: false,
      pointsAdd: 0
    };
  },
  components: {}
});
