
import { defineComponent } from "vue";
import PlayerComponent from "./components/Player.vue";
import ActivePlayerComponent from "./components/ActivePlayer.vue";
import { Player } from "./data-objects/player";

export default defineComponent({
  name: "App",
  components: {
    PlayerComponent,
    ActivePlayerComponent
  },
  data: function() {
    return {
      players: [new Player("Neuer Spieler")],
      activeIndex: 0
    };
  },
  methods: {
    addPlayer: function() {
      this.players.push(new Player("Neuer Spieler"));
    },
    removePlayer: function(player: Player) {
      const index = this.players.indexOf(player);
      if (index >= 0) {
        this.players.splice(index, 1);
        if (this.activeIndex > index) {
          this.activeIndex -= 1;
        }
      }
    },
    reset: function() {
      this.players.forEach(player => {
        player.points = 0;
        player.pointsRound = 0;
      });
    }
  }
});
