
import { defineComponent } from "vue";
import { CheckCircle, CloseCircle, DeleteForever, Pencil } from "mdue";

export default defineComponent({
  name: "Player",
  props: ["player"],
  data: function() {
    return {
      name: this.$props.player.name,
      isEditing: false,
      pointsAdd: 0
    };
  },
  components: {
    CheckCircle,
    CloseCircle,
    DeleteForever,
    Pencil
  }
});
